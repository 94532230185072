import styles from './intro.module.css';
import { Col, Row } from 'antd';
import ContentContainer from '../ContentContainer/ContentContainer';
import { RefObject } from 'react';
import classnames from 'classnames';

export type BlockType = 'green-bottom' | 'green-top' | 'white' | 'green';

type Props = {
  type: BlockType;
  title?: string;
  subTitle?: string;
  description: JSX.Element;
  ref?: RefObject<HTMLDivElement>;
  className?: string;
};

const IntroBlock = (props: Props): JSX.Element => {
  return (
    <Row
      ref={props?.ref}
      className={classnames(styles.intro, styles[props.type], props.className)}
      align="middle"
      justify="center"
      style={{ textAlign: 'center' }}
    >
      <ContentContainer>
        <>
          {props.title && (
            <Row>
              <Col span={24}>
                <h1>{props.title}</h1>
              </Col>
            </Row>
          )}
          {props.subTitle && (
            <Row style={{ paddingBottom: '32px' }}>
              <Col span={24}>
                <h2>{props.subTitle}</h2>
              </Col>
            </Row>
          )}
          <Row
            align="middle"
            justify="space-around"
            style={{ textAlign: 'center' }}
            gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]}
          >
            {props.description}
          </Row>
        </>
      </ContentContainer>
    </Row>
  );
};

export default IntroBlock;
